/**********************
  general layout
**********************/
html {
  width: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: GothamBook,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:11pt !important;
  width: 100%;
  min-height: 100vh; 
  background-color:#48d1cc;
  background-image: linear-gradient(90deg, #44C8C3, #95FFFB, #44C8C3);
}

a.cn-custom2{
  font-weight: bold;
  color: #0d6efd;
  text-decoration: none;
}

a.cn-custom2:hover{
  color:#696969;
}

/* #root{
  background-color:#48d1cc;
  background-image: linear-gradient(90deg, #44C8C3, #95FFFB, #44C8C3);
} */

h2{
  font-size: 18pt !important;
}

.h2-centered{
  font-size: 18pt !important;
  text-align: center;
}

h3{
  font-size: 14pt !important;
}

.h3-centered{
  font-size: 14pt !important;
  text-align: center;
}

h4{
  font-size:12pt !important;
}

.h4-centered{
  font-size:12pt !important;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container, .container-full-width, .container-width-4, .container-width-5, .container-width-6, 
.container-width-7, .container-width-8, .container-width-9{
  margin-top:30px !important;
  margin:auto;
  padding:0 10px;
}

.main-container{
  min-height: 100vh;
}

.container-full-width{
  width:100%;
}

.container-width-4{
  width:40%;
}

.container-width-5{
  width:50%;
}

.container-width-6{
  width:60%;
}

.container-width-7{
  width:70%;
}

.container-width-8{
  width:80%;
}

.container-width-9{
  width:90%;
}

.form-container{
  width:50%;
  margin:auto;
}

.login.container-width-4{
  width: 25% !important;
}

.cron-form label{
  min-height: 0px;
}

.cron-form .col{
  flex: 1 0 0%;
  margin-right: unset;
}

table{
  font-size:9pt;
  background-color:#ffffff;
}

table.container-width-5 td{
  padding:3px;
}

.client-import-container{
  max-width: 100%;
}

.client-import{
  overflow-x: scroll;
  overflow-y: hidden;
}

.client-import{
  margin-top: 5px;
  margin-bottom: 15px;
}

.client-import::-webkit-scrollbar, .client-import-wrapper::-webkit-scrollbar{
  width:6px;
}

.client-import::-webkit-scrollbar-track, .client-import-wrapper::-webkit-scrollbar-track{
  background: #ccc;
  border-radius: 20px;
}

.client-import::-webkit-scrollbar-thumb, .client-import-wrapper::-webkit-scrollbar-thumb{
  background-color: #6c757d;
  border-radius: 20px;
}

.client-import-modal table{
  border:solid 2px black;
}

.client-import-modal th{
  border:solid 1px black;
  text-align: center;
}

.client-import-modal td{
  border:solid 1px rgb(27, 27, 27);
}

.client-list li{
  list-style: none;
}

.message-list th:nth-of-type(2), .message-list td:nth-of-type(2){
  display: table-cell;
}

.task-list th:nth-of-type(3){
  visibility: hidden;
}
.task-list th:nth-of-type(3)::after{
  content: 'Message';
  visibility: visible;  
  position:relative;
  left: -20px;
}
.task-list th:nth-of-type(5){
  visibility: hidden;
}
.task-list th:nth-of-type(5)::after{
  content: 'Schedule';
  visibility: visible;
  position:relative;
  left: -20px;
}
.task-list th:nth-of-type(6){
  visibility: hidden;
}
.task-list th:nth-of-type(6)::after{
  content: 'Status';
  visibility: visible;
  position:relative;
  left: -16px;
}
.task-list th:nth-of-type(n+3):nth-of-type(-n+8), .task-list td:nth-of-type(n+3):nth-of-type(-n+8){
  padding: 0.5rem 2px;
}

.error-box{
  color: #842029;
  background-color: #f8d7da;
  border: solid 1px #f5c2c7;
  border-radius: 0.375rem;
  padding: 10px;
}

.Toastify table{
  font-size: 9pt !important;
}

.Toastify__toast-body{
  white-space: pre-line;
}
/**********************
  splash page
**********************/
.splash-container{
  padding-top:25px;
  width:80%;
  margin: auto;
}
.splash-title{
  font-size: 24pt !important;
  text-align: center;
}
.splash-card{
  display:flex;
  border-radius: 8px;
  margin: 40px 0;
}
.splash-image-odd{
  width: 70%;
  background-size: cover;
  height: 400px;
  border-top-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0px;
}
.splash-text-odd{
  width: 30%;
  background-color:white;
  font-size: 12pt;
  padding: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 8px;
}
.splash-text-odd:nth-last-of-type(2){
  width: 40%;
}
.splash-image-even{
  width: 50%;
  background-size: cover;
  height: 600px;
  border-top-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 8px;
}
.splash-text-even{
  width: 50%;
  background-color:white;
  font-size: 12pt;
  padding: 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0px;
}
.splash-card .image1{
  background-image: url('../public/images/clientpro-splash1.jpg');
}
.splash-card .image2{
  background-image: url('../public/images/clientpro-splash2.jpg');
}
.splash-card .image3{
  background-image: url('../public/images/clientpro-splash3.jpg');
  width: 60%;
  height: 506px;
  background-position: right;
}
.splash-card .text3{
  width: 40%;
}
/**********************
  subscription
**********************/
.subscription-plans{
  display: inline-flex;
}

.subscription-desc{
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 3px;
  font-size: 9pt;
  text-align: left;
}
/**********************
  recaptcha
**********************/
.recaptcha-container{
  margin-left: 35px;
}

/**********************
  navbar
**********************/
.navbar{
  font-size: 12pt !important;
}

.nav-item{
  margin: 0 4px;
}

.navbar-app-logo{
  width: 30px;
  height: 20px;
}

.navbar-app-name{
  color: #fff;
  font-size: 12pt;
  display: inline;
}

.navbar-current-user{
  width:auto;
  float:right;
  font-size:12pt;
  color:#adb5bd;
  margin-right: 10px;
}

.navbar-login-signup{
  width:auto;
  float:right;
  font-size: 12pt;
  color:#adb5bd;
  display: inline-flex;
}

.navbar-login-signup .nav-item{
  list-style: none;
  margin-left: 10px;
}

.nav-link{
  /*color:#0ABAB5 !important;*/
  color:#48d1cc !important
}

.nav-link:hover{
  color:#ffffff !important;
}

.badge{
  padding: 0.3em 0.65em !important;
}

.notifications{
  cursor:pointer;
  position:absolute;
  top: 18px;
  left: -2%;
  font-size: 8pt;
}
/************************
  cookies
************************/
.cookie-popup{
  width: 90%;
  border:solid 1px black;
  padding:15px;
  background-color: #fff;
  margin-bottom: -15px;
  margin:auto;
}
/************************
  footer
************************/
.footer-container{
  width: 100%;
  height: 50px;
  background-color: #212529;
  margin-top: 20px;
  color: #48d1cc;
  font-size: 8pt;
}
.footer-inner-container{
  width: 100%;
  text-align: center;  
  padding-top: 10px;  
}
.footer-container a{
  color: #48d1cc;
  font-size: 8pt !important;
  text-decoration: none;
}
/************************
  buttons
************************/
a,button{
  font-size:11pt !important;
}

a.cn-custom, button.cn-custom{
  --bs-btn-padding-y: .1rem;
  --bs-btn-padding-x: .4rem;
  --bs-btn-font-size: 9pt !important;
}

.badge-sm {
  min-width: 1.8em;
  padding: .25em !important;
  margin-left: 1em;
  margin-right: .1em;
  margin-bottom: .4em;
  color: #fff !important;
  cursor: pointer;
  border: solid 1px;
}

.disabled-link{
  pointer-events: none;
}

.disabled{
  pointer-events: none;
  background-color: lightgray;
}

/************************
  inputs
************************/
input,select,textarea{
  font-size: 11pt !important;
}

input.cn-custom, input.filters{
  padding: 0.1rem 0.5rem;
  font-size: 11pt !important;
}

select.cn-custom, select.filters{
  padding: 0.1rem 1.25rem 0.2rem 0.5rem;
  font-size: 11pt !important;
}

select.cn-custom-year{
  padding: 0.1rem 2rem 0.2rem 0.5rem;
  font-size: 11pt !important;
}

span.cn-custom{
  padding: 0px 4px !important;
  font-size: 8pt !important;
}

input.filters, select.filters{
  width:100%;
}

.input-hidden{
  visibility: hidden;
}

/************************
  calendar
************************/
.calendar-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 75%;
  color:#696969;
}

.calendar-header{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.calendar-selector-container-left{
  display: inline-flex;
  width: 33%;
}

.calendar-selector-container-middle{
  display: inline-flex;
  width: 33%;
  justify-content: center;
  padding-top: 16px;
  font-size: 14pt;
  font-weight: bold;
  text-transform: uppercase;
  color: #696969;
}

.calendar-selector-container-right{
  display: inline-flex;
  justify-content: right;
  width: 33%;
  padding-top: 18px;
  padding-right: 5px;
}

.calendar-selector-container-right svg{
  color:dimgray;
}

.calendar-body{
  width: 100%;
  margin-top: 3px;
}

.calendar-week-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.calendar-week-day-container{
  margin: 1px;
  border: 1px solid gray;
  text-align: center;
  height: 25px;
  color: #000;
}

.calendar-date-container{
  display: grid;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.calendar-card-weekday, .calendar-card-active, .calendar-card-disable, .calendar-card-today{
  margin: 2px;
}

.calendar-card-weekday{
  text-align: center;
}

.calendar-card-body-active, .calendar-card-body-disable, .calendar-card-body-today{
  max-height:65px;
  min-height:65px;
  padding: 2px !important;
}

.calendar-card-header-active, .calendar-card-header-disable, .calendar-card-header-today{
  max-height:15px;
  font-size: 7pt;
  padding: 3px !important;
  display: inline-flex;
}

.calendar-card-header-active, .calendar-card-header-today{
  cursor: pointer;
}

.calendar-card-header-day, .calendar-card-header-icon{
  width: 50%;
}

.calendar-card-header-icon{
  text-align: right;
  margin-bottom: 5px;
  height: 8px;
}

.calendar-card-header-icon svg{
  color: rgb(128 128 128);
  position: relative;
  top: -3px;
}

.calendar-card-today, .calendar-card-body-today{
  background-color: rgb(240, 233, 137) !important;
}

.calendar-card-header-today{
  background-color:rgb(250, 198, 67) !important;
}

.calendar-card-body-active, .calendar-card-body-today{
  font-size: 8pt;
  overflow: scroll;
  overflow-x: hidden;
  margin-bottom: 3px;
}
.calendar-card-body-today::-webkit-scrollbar, .calendar-card-body-active::-webkit-scrollbar{
  width:3px;
}
.calendar-card-body-today::-webkit-scrollbar-track{
  /* background: rgb(250, 198, 67); */
}
.calendar-card-body-active::-webkit-scrollbar-track{
  /* background: rgb(247, 247, 247); */
}
.calendar-card-body-today::-webkit-scrollbar-thumb{
  background-color: rgb(212, 154, 6);
  border-radius: 20px;
}
.calendar-card-body-active::-webkit-scrollbar-thumb{
  background-color: rgb(180, 180, 180);
  border-radius: 20px;
}
.calendar-card-body-active svg, .calendar-card-body-today svg{
  font-size: 7pt;
  color:dimgray;
}

.calendar-container ul{
  padding:0px;
}

.calendar-container li{
  list-style: none;
}

.calendar-container li a{
  font-size: 8pt !important;
  color: #696969;
  text-decoration: none;
}

.calendar-submit-modal{
  background-color: #48d1cc;
}
/************************
responsive
************************/
@media only screen and (max-width:915px){
  .navbar .nav-item.home{
    display: none !important;
  }
  .navbar .container-fluid{
    margin-left: 0px !important;
  }
  .navbar-app-name{
    display: none;
  }
}
@media only screen and (min-width:40rem) and (max-width:750px) {
  .navbar-app-name{
    position: absolute;
    left: 60px !important;
    display: inline;
  }
  .navbar .nav-item.home{
    display: list-item !important;
  }
  .cron-form .col{
    flex: auto;
    margin-right:15px;
  }
  /**********************
  splash page
  **********************/
  .splash-container{
    padding-top:25px;
    width:100%;
    margin: auto;
  }
  .splash-title{
    font-size: 20pt !important;
    text-align: center;
  }
  .splash-card{
    display:flex;
    border-radius: 8px;
    margin: 20px 0;
  }
  .splash-image-odd{
    width: 70%;
    background-size: cover;
    height: 300px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .splash-text-odd{
    width: 30%;
    background-color:white;
    font-size: 10pt;
    padding: 15px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .splash-image-even{
    width: 60%;
    background-size: cover;
    height: 600px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .splash-text-even{
    width: 40%;
    background-color:white;
    font-size: 10pt;
    padding: 15px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
/************************
tablet
************************/
/*@media only screen and (max-width:768px) { */
@media only screen and (max-width:880px) {
  .navbar-app-name{
    position: absolute;
    left: 45px;
  }
  .navbar-current-user{
    float:left;
  }
  table.client-list{
    table-layout: fixed;
    display:block;
    overflow-x: auto;
  }
  .form-container{
    width:75%;
    margin:auto;
  }
  .subscription-plans{
    display: block;
  }
}

/************************
mobile
************************/
@media only screen and (max-width:40rem){
  .main-container{
    min-height: 87vh;
  }
  a, button{
    font-size: 9pt !important;
  }
  .btn{
    padding:2px 2px;
  }
  .container-width-5{
    width: 80%;
  }
  .container-width-7{
    width: 90%;
  }
  .navbar .nav-item.home{
    display: list-item !important;
  }
  .navbar-app-name{
    position: absolute;
    left: 60px;
    display: inline;
  }
  .navbar-current-user{
    float:left;
  }
  .notifications{
    padding-top: 2px;
  }
  .modal-dialog{
    /* top: -270px; */
  }
  table.client-import{
    table-layout: fixed;
    display:block;
    overflow-x: auto;
  }
  table.client-list{
    table-layout: fixed;
    display:block;
    overflow-x: auto;
  }
  table.task-list{
    table-layout: fixed;
    display:block;
    overflow-x: auto;
  }
  .form-container{
    width:75%;
    margin:auto;
  }
  .cron-form .col{
    flex: auto;
    margin-right:15px;
  }
  .message-list th:nth-of-type(2), .message-list td:nth-of-type(2){
    display: none;
  }
  .task-list th:nth-of-type(3){
    visibility: hidden;
  }
  .task-list th:nth-of-type(3)::after{
    content: 'Msg';
    visibility: visible;
    position:relative;
    left: -20px;
  }
  .task-list th:nth-of-type(5){
    visibility: hidden;
  }
  .task-list th:nth-of-type(5)::after{
    content: 'Sched';
    visibility: visible;
    position:relative;
    left: -20px;
  }
  .task-list th:nth-of-type(6){
    visibility: hidden;
  }
  .task-list th:nth-of-type(6)::after{
    content: 'Stats';
    visibility: visible;
    position:relative;
    left: -16px;
  }
  .task-list th:nth-of-type(n+3):nth-of-type(-n+8), .task-list td:nth-of-type(n+3):nth-of-type(-n+8){
    padding: 0.5rem 2px;
  }
  .calendar-container{
    width:100%;
  }
  .calendar-container li a{
    font-size: 7pt !important;
    line-height: 0px;
  }
  .login.container-width-4{
    width: 80% !important;
  }
  .subscription-plans{
    display: block;
  }
  /* recaptcha */
  .recaptcha-container{
    margin-left: 0px;
  }
  /**********************
  splash page
  **********************/
  .splash-container{
    padding-top:25px;
    width:100%;
    margin: auto;
  }
  .splash-title{
    font-size: 20pt !important;
    text-align: center;
  }
  .splash-card{
    display:flex;
    flex-direction: column;
    border-radius: 8px;
    margin: 20px 0;
  }
  .splash-image-odd{
    width: 100%;
    background-size: cover;
    height: 300px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .splash-text-odd{
    width: 100%;
    background-color:white;
    font-size: 10pt;
    padding: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .splash-image-even{
    width: 100%;
    background-size: cover;
    height: 600px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    order: 1;
  }
  .splash-text-even{
    width: 100%;
    background-color:white;
    font-size: 10pt;
    padding: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    /* display: flex; */
    order: 2;
  }
  .splash-card .image3{
    background-image: url('../public/images/clientpro-splash3.jpg');
    width: 100%;
    height: 506px;
    background-position: right;
  }
  .splash-card .text3{
    width: 100%;
  }
}